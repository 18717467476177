import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {Button, Dialog, DialogContent, Divider, Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';

import {activeComponent, activeItem, activeModal, endComponent, getOpenModal} from '../../../../app/store/reducers/menu';
import {DialogTitleWithCloseIcon} from '../../@extended/CustomDialogs';
import {getDashboardAccount, setDashboard} from '../../../../app/store/reducers/dashboard';
import {
  useAddCommunityMutation,
  useEditCommunityMutation,
  useLazyGetCommunitiesByAccountSfidQuery
} from '../../../../features/coreApi/communitiesSlice';
import {getCommunities} from '../../../../app/store/reducers/entities';

import SidebarMenu from './SidebarMenu';
import ChooseCommunity from './ChooseCommunity';
import ChooseCampaignType from './ChooseCampaignType';
import ChooseGoal from './ChooseGoal';
import EditMetrics from './EditMetrics';
import RecommendedCampaign from './RecommendedCampaign';
import {usePostRecommendedCampaignMutation} from '../../../../features/actionsApi/campaignsSlice';

const campaignSchema = Yup.object().shape({
  community_sfid: Yup.string().required(),
  community_id: Yup.string().required(),
  campaign_id: Yup.string().required(),
  community: Yup.object().shape({
    name: Yup.string().required('Name is required'),
    address: Yup.string().required('Address is required'),
    units: Yup.number().required('Units amount is required'),
    zipcode: Yup.string().required('Zipcode is required').min(5, 'Zipcode must be 5 characters'),
    rent: Yup.number('Rent is required').required().min(1, "Rent can't be less than $1").max(20000, "Rent can't be more than $20,000"),
    link: Yup.string('Link is required').required('Link is required')
  }),
  campaign_type: Yup.string().required(),
  goals: Yup.array().required(),
  metrics: Yup.object().shape({
    baseline_occupancy__c: Yup.number().typeError('Please, provide a number').required('This field is required'),
    baseline_bad_debt_rate__c: Yup.number().typeError('Please, provide a number').required('This field is required'),
    previous_concessions__c: Yup.number().typeError('Please, provide a number').required('This field is required'),
    target_new_lease_trade_out_rate__c: Yup.number().typeError('Please, provide a number').required('This field is required'),
    baseline_renewal_trade_out_rate__c: Yup.number().typeError('Please, provide a number').required('This field is required'),
    target_renewal_trade_out_rate__c: Yup.number().typeError('Please, provide a number').required('This field is required')
  })
});

const NewCampaign = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getOpenModal);
  const dashboadrAccount = useSelector(getDashboardAccount);
  const communities = useSelector(getCommunities);
  const [currentPage, setCurrentPage] = useState(0);

  const methods = useForm({
    defaultValues: {
      community_sfid: '',
      community_id: '',
      campaign_id: '',
      community: {
        name: '',
        address: '',
        units: 0,
        zipcode: 0,
        rent: '',
        link: ''
      },
      campaign_type: null,
      goals: [],
      metrics: {
        baseline_occupancy__c: null,
        baseline_bad_debt_rate__c: null,
        previous_concessions__c: null,
        target_new_lease_trade_out_rate__c: null,
        baseline_renewal_trade_out_rate__c: null,
        target_renewal_trade_out_rate__c: null
      }
    },
    mode: 'all',
    resolver: yupResolver(campaignSchema)
  });

  const {reset, getValues, watch} = methods;

  const [
    addCommunity,
    {isSuccess: isAddingComSuccess, isFetching: isAddingComFetching, isLoading: isAddingComLoading, isError: isAddingComError}
  ] = useAddCommunityMutation();
  const [fetchCommunities, {isLoading: isComLoading, isFetching: isComFetching}] = useLazyGetCommunitiesByAccountSfidQuery();
  const [
    updateCommunity,
    {isSuccess: isEditComSuccess, isLoading: isEditComLoading, isFetching: isEditComFetching, isError: isEditComError}
  ] = useEditCommunityMutation();
  const [postRecommendedCampaign, {data: recCampaign, isSuccess, isLoading, isFetching, isError}] = usePostRecommendedCampaignMutation();

  const isOpen = useMemo(() => {
    return modal.type === 'startCampaign';
  }, [modal]);

  const handleClose = () => {
    reset();
    setCurrentPage(0);
    dispatch(activeModal({type: null, sfid: null}));
  };

  const onSubmit = () => {
    const formData = getValues();
    const stringGoals = formData.goals.join(',');
    const dataForm = new FormData();

    dataForm.append('goals_optimized_for', stringGoals);
    dataForm.append('rental_community_id', formData.community_id);
    dataForm.append('account_sfid', 'account_sfid');

    const obj = {
      data: dataForm,
      campaignType: formData.campaign_type
    };

    postRecommendedCampaign(obj);
  };

  useEffect(() => {
    if (isSuccess && !isLoading && !isFetching && !isError) {
      dispatch(endComponent('campaigns'));
      dispatch(activeComponent('home'));
      dispatch(activeItem('campaigns'));
      dispatch(
        setDashboard({
          type: 'campaigns',
          data: {name: recCampaign[0]?.name, sfid: recCampaign[0]?.id, property_sfid: recCampaign[0]?.property_sfid}
        })
      );
      dispatch(activeModal({type: null, sfid: null}));
    }
  }, [isSuccess, isLoading, isFetching, isError]);

  const currentPageComponent = useMemo(() => {
    if (currentPage < 1) {
      return <ChooseCommunity setCurrentPage={setCurrentPage} currentPage={currentPage} />;
    } else if (currentPage === 1) {
      return <ChooseCampaignType setCurrentPage={setCurrentPage} />;
    } else if (currentPage === 2) {
      return <ChooseGoal />;
    } else if (currentPage === 3) {
      return <EditMetrics />;
    } else if (currentPage === 4) {
      return <RecommendedCampaign />;
    }
  }, [currentPage, communities]);

  const handleBack = () => {
    if (currentPage < 1) {
      setCurrentPage(0);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const isCommunityComplete = Object.values(watch('community')).every((value) => value);

  const createCommunity = () => {
    const data = getValues();
    const community = data.community;
    let formData = new FormData();

    formData.append('address', community['address']);
    formData.append('account_sfid', dashboadrAccount.sfid);
    formData.append('name', community['name']);
    formData.append('total_units', community['units']);
    formData.append('listings_link', community['link']);
    formData.append('zip_code', community['zipcode']);
    formData.append('estimated_monthly_rent__c', community['rent']);

    addCommunity(formData);
  };

  useEffect(() => {
    if (isAddingComSuccess && !isAddingComFetching && !isAddingComLoading && !isAddingComError) {
      setTimeout(() => {
        fetchCommunities(dashboadrAccount.sfid);
      }, 1000);
    }
  }, [isAddingComSuccess, isAddingComFetching, isAddingComLoading, isAddingComError]);

  const viewRecommendedCampaign = () => {
    const values = getValues();
    const metricsValues = {};

    for (const key in values?.metrics) {
      if (key == 'previous_concessions__c') {
        metricsValues[key] = values.metrics[key];
        continue;
      }
      metricsValues[key] = parseFloat(values.metrics[key]);
    }

    updateCommunity({id: values?.community_id, data: metricsValues});
  };

  useEffect(() => {
    if (isEditComSuccess && !isEditComFetching && !isEditComLoading && !isEditComError && currentPage === 3) {
      setTimeout(() => {
        fetchCommunities(dashboadrAccount.sfid);
      }, 1000);
      setCurrentPage(currentPage + 1);
    }
  }, [isEditComSuccess, isEditComFetching, isEditComLoading, isEditComError]);

  return (
    <Dialog open={isOpen} onClose={() => handleClose()} fullWidth maxWidth={'lg'}>
      <DialogTitleWithCloseIcon onClose={() => handleClose()}>New Campaign</DialogTitleWithCloseIcon>
      <DialogContent dividers sx={{pt: 0, height: '80vh'}}>
        <FormProvider {...methods}>
          <form style={{width: '100%', height: '100%'}}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem sx={{marginTop: -2, marginBottom: -2}} />}
              height="100%"
            >
              <SidebarMenu currentPage={currentPage} />
              <Stack direction="column" width="100%" height={'100%'} justifyContent={'space-between'}>
                {currentPageComponent}
                <Stack direction="row" justifyContent="space-between" alignItems="flex-end" width="100%">
                  {currentPage >= 0.5 && (
                    <Button sx={{textDecoration: 'underline'}} onClick={() => handleBack()}>
                      Back
                    </Button>
                  )}
                  {currentPage == 0.5 && (
                    <LoadingButton
                      loading={isAddingComFetching || isAddingComLoading || isComFetching || isComLoading}
                      variant="contained"
                      color={'secondary'}
                      onClick={() => createCommunity()}
                      disabled={!isCommunityComplete}
                    >
                      Create
                    </LoadingButton>
                  )}
                  {currentPage == 2 && (
                    <Button
                      variant="contained"
                      color={'secondary'}
                      onClick={() => setCurrentPage(3)}
                      disabled={watch('goals').length === 0}
                    >
                      Next
                    </Button>
                  )}
                  {currentPage == 3 && (
                    <LoadingButton
                      variant="contained"
                      color={'secondary'}
                      onClick={() => viewRecommendedCampaign()}
                      disabled={watch('goals').length === 0}
                      loading={isEditComFetching || isEditComLoading || isComFetching || isComLoading}
                    >
                      View Recommended Campaigns
                    </LoadingButton>
                  )}
                  {currentPage == 4 && (
                    <Button onClick={() => onSubmit()} variant="contained" color={'secondary'} disabled={watch('campaign_id').length === 0}>
                      View Campaign
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default NewCampaign;
