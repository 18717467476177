import axios from 'axios';
import {createApi} from '@reduxjs/toolkit/query/react';

import CONSTANTS from '../../commons/constants/envConstants';
import {getCurrentToken, initializeToken} from '../../commons/utils/cookies';

initializeToken();

const axiosBaseQuery =
  () =>
  async ({url, method, data, params, headers}) => {
    try {
      const baseUrl = CONSTANTS.BASE_API_URL;
      const token = await getCurrentToken();
      const configuredHeaders = {
        'X-Session-Token': token,
        'Content-Type': 'application/json',
        dealsAPIKey: CONSTANTS.BASE_API_KEY,
        ...headers
      };

      const result = await axios({
        url: `${baseUrl}${url}`,
        method,
        data,
        params,
        headers: configuredHeaders
      });

      return {data: result.data?.data ?? result.data};
    } catch (axiosError) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message
        }
      };
    }
  };

export const coreApiSlice = createApi({
  reducerPath: 'coreApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAccount: builder.query({
      query: (sfid) => ({
        url: 'accounts/',
        params: {sfid},
        method: 'get'
      }),
      providesTags: (_res, err, arg) => !err && [{type: 'Accounts', id: arg}]
    }),
    getAccounts: builder.query({
      query: () => ({
        url: 'accounts/list/',
        method: 'get'
      }),
      providesTags: [{type: 'Accounts', id: 'LIST'}],
      transformResponse: (response) =>
        response.filter((account) => account.sfid && account.name).sort((a, b) => a.name.localeCompare(b.name))
    }),
    getAccountAlerts: builder.query({
      query: (sfid) => ({
        url: `accounts/${sfid}/alerts/`,
        method: 'get'
      })
    }),
    getAccountInvoice: builder.query({
      query: (sfid) => ({
        url: `invoice/${sfid}`,
        method: 'get'
      })
    }),
    getPastInvoicesList: builder.query({
      query: (sfid) => ({
        url: `invoice/${sfid}/past/`,
        method: 'get'
      })
    })
  })
});

export const {
  useLazyGetAccountQuery,
  useLazyGetAccountsQuery,
  useLazyGetAccountAlertsQuery,
  useLazyGetAccountInvoiceQuery,
  useLazyGetPastInvoicesListQuery
} = coreApiSlice;
