import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {NumericFormat, PatternFormat} from 'react-number-format';
import {useTheme} from '@mui/material';

export const NumericFormatCustomDisplay = forwardRef(function NumericFormatCustomDisplay(props, ref) {
  const {value, ...other} = props;

  return <NumericFormat ref={ref} {...other} displayType="text" value={value} thousandSeparator />;
});

NumericFormatCustomDisplay.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export const MoneyFormatCustomDisplay = forwardRef(function MoneyFormatCustomDisplay(props, ref) {
  const {value, ...other} = props;
  const theme = useTheme();

  return (
    <NumericFormat
      ref={ref}
      prefix={value < 0 ? '($' : '$'}
      suffix={value < 0 ? ')' : ''}
      displayType="text"
      value={Math.abs(value)}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale={true}
      style={{
        color: other?.isColored ? (value < 0 ? theme.palette.error.main : theme.palette.success.main) : theme.palette.primary.main
      }}
    />
  );
});

MoneyFormatCustomDisplay.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export const PhoneFormatCustomDisplay = forwardRef(function PhoneFormatCustomDisplay(props, ref) {
  const {value, ...other} = props;
  return <PatternFormat ref={ref} {...other} displayType="text" format="+# (###) ###-####" value={value} />;
});

PhoneFormatCustomDisplay.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export const MoneyFormatCustom = React.forwardRef(function MoneyFormatCustom(props, ref) {
  const {onChange, onBlur, value, ...other} = props;
  return (
    <NumericFormat
      {...other}
      prefix={value < 0 ? '($' : '$'}
      suffix={value < 0 ? ')' : ''}
      displayType="input"
      value={Math.abs(value)}
      thousandSeparator
      decimalScale={2}
      onChange={onChange}
      onBlur={onBlur}
      fixedDecimalScale={true}
      allowNegative={false}
      getInputRef={ref}
    />
  );
});

MoneyFormatCustom.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

export const PositiveNumberFormat = React.forwardRef(function PositiveNumberFormat(props, ref) {
  const {onChange, onBlur, value, ...other} = props;

  return (
    <NumericFormat
      {...other}
      displayType="input"
      value={value}
      getInputRef={ref}
      allowNegative={false}
      allowLeadingZeros={false}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
});

PositiveNumberFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

export const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const moneyFormatterDecimal = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  useGrouping: true
});
